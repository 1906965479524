import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import './SelectPhotos.css';
import { useNavigate } from 'react-router-dom'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { AuthAPI } from './apis/auth-api';
import { RelivesAPI } from './apis/relives-api';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(0, 0, 0, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(0, 0, 0, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(0,255,255,0.1) inset',
  py: 1,
  px: 3,
};
export default function SelectPhotos(props) {
  let [selectAll, setSelectAll] = React.useState(false);
  let [selections, setSelections] = React.useState(Array((props.thumbs || []).length).fill(false));
  const navigate = useNavigate();
  const onPhotoSelected = (e, index) => {
    if (e.target.checked) {
      selections[index] = true;
    } else {
      selections[index] = false;
    }
    setSelections([...selections]);
  }

  // const onSelectAll = async () => {
  //   setSelections(selections.fill(!selectAll))
  //   setSelectAll(!selectAll)
  // }

  const chooseSelected = async (image) => {
    // const images = selections.map((selected, index) => {
    //   if (selected) {
    //     const img = photos[index];
    //     return img;//img.substring(img.lastIndexOf('/') + 1, img.indexOf('?'))
    //   } else {
    //     return null;
    //   }
    // }).filter(val => val != null);
    // console.log(images)
    // if(props.onSelect)
    props.onSelect(image)
  }

  let [selectedIndex, setSelectedIndex] = React.useState(0);
  let [relives, setRelives] = React.useState(null);
  let [photos, setPhotos] = React.useState([]);
  let [photoThumbs, setPhotoThumbs] = React.useState([]);
  const isActive = (index, selectedIndex) => {
    return index === selectedIndex
  }
  React.useEffect(() => {
    async function fetchData() {
      try {
        if (!AuthAPI.getUser()) {
          return navigate('/login')
        }
        const relives = await RelivesAPI.getRelives();
        setRelives(relives)
        onReliveSelected(relives[0].eventcode)
      } catch (ex) {
        console.log('error fetching relives', ex)
      }
    }
    fetchData();
  }, []);

  const onReliveSelected = async (relivId, index) => {
    try {
      if (!relivId) {
        throw new Error(`RelivId is missing`);
      }
      if (!AuthAPI.getUser()) {
        return navigate('/login')
      }
      setSelectedIndex(index)
      const reliveMedia =await RelivesAPI.getReliveMedia(relivId)
      // console.log(reliveMedia)
      const thumbs = reliveMedia.data.map(item => `${reliveMedia.thumbs_base}${item.name}?${reliveMedia.token}`)
      const images = reliveMedia.data.map(item => `${reliveMedia.view_base}${item.name}?${reliveMedia.token}`)
      setPhotos(images)
      setPhotoThumbs(thumbs)
    } catch (ex) {
      console.log('error', ex)
    }
  }

  return (

    <div class="Parent">
      <div class="child1">
        {relives && relives.map(({ eventcode, name, active }, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton selected={isActive(index, selectedIndex)} onClick={(e) => onReliveSelected(eventcode, index)}>
              <ListItemText>{name}</ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </div>
      <div class="child2">
        {/* <Toolbar>
            <Grid container spacing={2} alignItems="left">
              <Grid item>
                <Button variant="contained" onClick={chooseSelected}>
                  Select
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={chooseSelected}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Toolbar> */}
        <ImageList
          gap={12}
          sx={{
            mb: 8,
            gridTemplateColumns: 'repeat(auto-fill, minmax(150px,1fr)) !important'
          }}
        >
          {photoThumbs && photoThumbs.map((item, index) => (
            <ImageListItem
              onClick={() => props.onSelect(photos[index])}
              sx={{ width: '150px !important' }}
              key={item}>
              <img
                src={item}
                alt=""
              />;
              {/* <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                }}
                position="top"
                actionIcon={
                  <Checkbox sx={{ color: 'white' }} onChange={(e) => onPhotoSelected(e, index)} checked={selections[index] || false} />
                }
                actionPosition="left"
              /> */}
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
}


import * as React from 'react';
import { Avatar, Box, IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import SelectTemplate from './SelectTemplate'
import thumb from './collage-icon.png';
import { useNavigate } from 'react-router-dom'

import addIcon from './add-icon.png';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -42%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'scroll',
    height: '100%',
    display: 'block'
};
export default function CollageListing(props) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();

    return (
        <>
            <Stack direction="row"
                spacing={2}
                padding={"20px"}
                sx={{
                    overflow: "hidden",
                    overflowX: "scroll",
                }}
            >
                {props.collages && props.collages.map((item, index) => (
                    <IconButton onClick={() =>  navigate(`/collage/${item.id}`)} key={index} >
                        <img src={item.thumb} key={index} />
                    </IconButton>
                ))}
                <IconButton onClick={handleOpen}  >
                    <img src={addIcon} />
                </IconButton>
            </Stack>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <DialogTitle>Select Template</DialogTitle>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <SelectTemplate></SelectTemplate>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}


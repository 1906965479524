import React, { useCallback, useState, useRef } from 'react';
import './Collage.css';
import { Button, Card, Checkbox, Grid, IconButton, ImageListItemBar, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Modal from '@mui/material/Modal';
import SelectPhotos from './SelectPhotos';
import AddIcon from '@mui/icons-material/Add';
import { v4 } from 'uuid'

const style = {
    position: 'absolute',
    top: '44%',
    left: '50%',
    transform: 'translate(-50%, -42%)',
    width: 800,
    bgcolor: '#fff',
    border: '2px solid grey',
    overflow: 'scroll',
    height: '100%',
    display: 'block'
};
let keyCount = 1;
const getKey = () => {
    return keyCount++;
}

const Image = ({ size, src, id, onPhotoSelected, onSwapPhotos }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleSelection = (photo) => {
        setOpen(false)
        onPhotoSelected(id, photo);
    }
    const handleOnDrage = (e, id) => {
        e.dataTransfer.setData("id", id);
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    }
    const handleOnDrop = (e, targetId) => {
        const sourceId = e.dataTransfer.getData("id");
        onSwapPhotos(sourceId, targetId);
    }
    return (
        <>
            {src && <div draggable onDragStart={(e) => handleOnDrage(e, id)} onDragOver={handleDragOver} onDrop={(e) => handleOnDrop(e, id)} className='image' style={{ flexBasis: `${size * 100}%`,display: 'flex', alignItems: 'center', justifyContent: 'center',  backgroundImage: `url(${src})` }} >
            <IconButton onClick={handleOpen} style={{ width: '100px', height: '100px', boder: '1px solid grey' }}><AddIcon /></IconButton>
            </div>}
            {!src && <div className='solid' style={{ flexBasis: `${size * 100}%`, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <IconButton onClick={handleOpen} style={{ width: '100px', height: '100px', boder: '1px solid grey' }}><AddIcon /></IconButton>
            </div>}
            <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <DialogTitle>Select Photos</DialogTitle>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            <SelectPhotos onSelect={handleSelection}></SelectPhotos>
                        </Typography>
                    </Box>
                </Modal>
        </>
    )
};


export default function CollageWrapper({ template, border, onPhotoSelected, onSwapPhotos})  {
    return (
        <div key={getKey()} className='collage' style={{ flexDirection: template.orientation, flexBasis: `${template.size * 100}%` }}>
            {[...(template.images || []), ...(template.collages || [])].map((child, i) => (
                <React.Fragment key={v4()} >
                    {i > 0 && template.orientation === "row" && <div key={v4()} style={{ width: `${border}px`, flexShrink: 0 }} />}
                    {i > 0 && template.orientation === "column" && <div key={v4()} style={{ height: `${border}px`, flexShrink: 0 }} />}
                    {child.type === "image" && <Image key={v4()} onSwapPhotos={onSwapPhotos} onPhotoSelected={onPhotoSelected} size={child.size} src={child.src} id={child.id} />}
                    {child.type === "collage" && <CollageWrapper key={v4()} onPhotoSelected={onPhotoSelected} onSwapPhotos={onSwapPhotos}  template={child} border={template.border} />}
                </React.Fragment>
            ))}
        </div>
    )
};


import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { Container } from '@mui/system';
import { Avatar, Box, IconButton, Stack } from '@mui/material';

export default function RelivFaces(props) {
    // let [faces, setFaces] = React.useState(null);
    // const navigate = useNavigate();
    // React.useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             if (!props.relivId) {

    //                 throw new Error(`RelivId is missing`);
    //             }
    //             if (!props.user || !props.user.token) {
    //                 navigate('/login')
    //             }
    //             let headers = { "Authorization": `Bearer ${props.user.token || ''}` };
    //             const response = await fetch(`/v2/memory/${props.relivId}/faces`, { headers, withCredentials: true });
    //             if (!response.ok && response.status === 401) {
    //                 navigate('/login')
    //             }
    //             const data = await response.json()
    //             if (Array.isArray(data.faces)) {
    //                 setFaces(data.faces)
    //             }
    //         } catch (ex) {
    //             console.log('error', ex)
    //         }
    //     }
    //     fetchData();
    // }, [props.relivId]);
    // const selectFace = (item) => {
    //     console.log(item)
    // }
    return (
                <Stack direction="row" 
                    spacing={2} 
                    padding={"20px"}
                    sx={{
                        overflow: "hidden",
                        overflowX: "scroll",
                      }}
                    >
                    {props.faces && props.faces.faces.map((item, index) => (
                        <IconButton onClick={()=>props.onFaceSelected(item)} key={item.face} >
                        <Avatar src={props.faces.face_base + item.face + '?' + props.faces.token} sx={{ width: 100, height: 100 }} key={item.face}/>
                        </IconButton>
                    ))}
                </Stack>
    );
}


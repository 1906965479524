import { AuthAPI } from './auth-api';
import { apiKey, baseUrl } from '../config';
let relives = null;
console.log('baseurl', baseUrl)
export const RelivesAPI = {
    getPhotoUploadUrl: async (eventcode, token) => {
        let headers = { "Authorization": `Bearer ${token}`, 'X-API-KEY': `${apiKey}` };
        const response = await fetch(`${baseUrl}/camera/signed-url?eventcode=${eventcode}`, { headers });
        return await response.json()
    },
    getRelives: async () => {
        if (!relives && !localStorage.getItem('relives')) {
            let headers = { "Authorization": `Bearer ${AuthAPI.getUser().token}`, 'X-API-KEY': `${apiKey}` };
            const response = await fetch(`${baseUrl}/relives`, { headers });
            if (!response.ok && response.status === 401) {
                //throw auth erro
            }
            const data = await response.json()
            if (!Array.isArray(data.message)) {
                //throw no data error
            }
            relives = data.message;
            localStorage.setItem('relives', JSON.stringify(relives))
            return relives
        } else {
            return JSON.parse(localStorage.getItem('relives') || [])
        }
    },
    getReliveMedia: async (relivId, pageSize = 1000) => {
        if (!localStorage.getItem(`relive_${relivId}`)) {
            let headers = { "Authorization": `Bearer ${AuthAPI.getUser().token}`, 'x-api-key': `${apiKey}` };
            const response = await fetch(`${baseUrl}/v2/relives/${relivId}/media?limit=500`, { headers, withCredentials: true });
            if (!response.ok && response.status === 401) {
                //throw auth erro
            }
            const data = await response.json()
            localStorage.setItem(`relive_${relivId}`, JSON.stringify(data.message))
            return data.message
        } else {
            return JSON.parse(localStorage.getItem(`relive_${relivId}`))
        }
    },

    getReliveFaces: async (relivId) => {
        if (!localStorage.getItem(`relive_${relivId}_faces`)) {
            let headers = { "Authorization": `Bearer ${AuthAPI.getUser().token}`, 'x-api-key': `${apiKey}` };
            const response = await fetch(`${baseUrl}/relives/${relivId}/faces`, { headers, withCredentials: true });
            if (!response.ok && response.status === 401) {
                //throw auth erro
            }
            const data = await response.json()
            console.log(data)
            // if (!Array.isArray(data.faces)) {
            //     //throw erro
            // }
            console.log('storing')
            localStorage.setItem(`relive_${relivId}_faces`, JSON.stringify(data))
            console.log(data)
            return data
        } else {
            return JSON.parse(localStorage.getItem(`relive_${relivId}_faces`))
        }
    },
    downloadPhotos: async (relivId, images) => {
        const requestOptions = {
            method: 'POST',
            headers: { "Authorization": `Bearer ${AuthAPI.getUser().token || ''}` },
            body: JSON.stringify({ reliveId: relivId, photos: images }),
            withCredentials: true
        };
        const response = await fetch(`${baseUrl}/memory/download`, requestOptions);
        if (!response.ok && response.status === 401) {
            //throw auth erro
        }
        const data = await response.json()
        return data
    }
}
import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Button, Box, Container, Grid, Snackbar, IconButton, Alert, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { AuthAPI } from './apis/auth-api';
import { RelivesAPI } from './apis/relives-api';
import { useSearchParams, useParams } from 'react-router-dom';
import './Photos.css'

const theme = createTheme();

function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
    }

    // Check for Android
    if (/android/i.test(userAgent)) {
        return 'android';
    }

    // Default to unknown
    return 'ios';
}


export default function Photos(props) {
    const navigate = useNavigate();
    const [os, setOs] = useState('');
    const [reliveExist, setReliveExist] = React.useState(null);
    const [reliveExpired, setReliveExpired] = React.useState(null);
    const [reliveNotStarted, setReliveNotStarted] = React.useState(null);
    const [searchParams] = useSearchParams();
    const params = useParams()
    const id = params.id;
    const name = searchParams.get('name');
    const link = searchParams.get('link');
    const image = searchParams.get('image')
    const Key = searchParams.get("Key-Pair-Id");
    const Signature = searchParams.get("Signature")
    const Code = searchParams.get("relive_code")
    const [open, setOpen] = useState(false);
    // const imageWithKey = `${image}&Key-Pair-Id=${Key}&Signature=${Signature}`;

    useEffect(() => {
        const operatingSystem = getMobileOperatingSystem();
        setOs(operatingSystem);
    }, []);

    let imageWithKey = "";
    console.log(Key, "key")
    console.log(Signature, "Signature")

    if (Signature && Key) {
        imageWithKey = `${image}&Key-Pair-Id=${Key}&Signature=${Signature}`;
    } else {
        imageWithKey = "/logo.png";
    }
    console.log(imageWithKey, "imageWithKey")
    // Use a fallback image if 'imageWithKey' is not valid
    const imageSrc = imageWithKey ? imageWithKey : '/logo.png';
    console.log(imageSrc, "imaheSrc")

    console.log(Key, "key")
    console.log(Signature, "Signature")

    const fileSelected = async (event) => {
        const file = event.target.files[0];
        console.log(`Selected file - ${file.name}`);
        console.log('eventcode:', id)
        if (!params || !id) {
            console.log('eventcode is missing')
        } else {
            const response = await RelivesAPI.getPhotoUploadUrl(id);
            console.log(response)
            if (response && response.url) {
                uploadFile(file, response.url)
            } else {

            }
        }
    }
    const uploadFile = async (file, presignedUploadUrl) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.addEventListener("progress", (e) => {
            if (e.lengthComputable) {
                const pct = e.loaded / e.total;
                console.log(pct * 100);
            }
        });
        xhr.upload.addEventListener("error", (e) => {
            console.log("Upload failed: " + e.toString());
            console.log(e)
        });
        xhr.upload.addEventListener("abort", (e) => {
            console.log("Upload aborted: " + e.toString());
        });
        xhr.addEventListener("load", (e) => {
            if (xhr.status === 200) {
                console.log('done uploading');
            } else {
                console.log("Upload failed " + xhr.status);
            }
        });
        console.log('uploading')
        console.log(presignedUploadUrl)
        xhr.open("PUT", presignedUploadUrl, true);
        try {
            xhr.send(file);
        } catch (e) {
            console.log("Upload failed: " + e.toString());
        }

    }
    React.useEffect(() => {
        async function fetchData() {
            try {
                if (!params || !id) {
                    console.log('eventcode is missing')
                } else {
                    const response = await RelivesAPI.getPhotoUploadUrl(id);
                    if (response && response.code !== undefined) {
                        if (response.code === 4000) {
                            setReliveExist(false)
                            setReliveNotStarted(true)
                        } else if (response.code === 4001) {
                            setReliveExist(false)
                            setReliveExpired(true)
                        } else {
                            setReliveExist(true)
                        }
                    } else {
                        setReliveExist(false)
                    }

                }
            } catch (ex) {
                setReliveExist(false)
                console.log(ex)
            }
        }
        fetchData();

    }, []);

    const handleCopy = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(Code)
                .then(() => {
                    setOpen(true); // Show the toast
                })
                .catch(err => {
                    console.error("Failed to copy: ", err);
                });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = Code;
            textArea.style.position = "fixed";
            textArea.style.opacity = "0";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setOpen(true); // Show the toast
                } else {
                    console.error('Failed to copy code.');
                }
            } catch (err) {
                console.error("Fallback: Oops, unable to copy", err);
            }
            document.body.removeChild(textArea);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {os === "ios" ? (
                <Container maxWidth="xl" style={{ padding: 0, boxSizing: 'border-box', overflow: 'hidden', backgroundColor: "#FFF7D4", maxHeight: "100vh" }}>
                    <div maxWidth="lg" style={{
                        padding: 0, boxSizing: 'border-box', overflow: 'hidden',
                        minHeight: "100vh",
                        padding: "0px 0px"
                    }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "end", overflow: 'hidden', marginRight: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "end", flexDirection: "column" }} >
                                <div> <p style={{ padding: 5, margin: 0, color: "#0C84FE", fontWeight: 600, fontSize: 15 }}>Click to join</p></div>
                                <div><i style={{ padding: 5, margin: 0, color: "#C80607", fontWeight: 900, fontSize: 15 }}>without installing app</i></div>
                            </div>
                            <img src="/arrow.png" alt='' style={{ width: 60, height: 60, marginLeft: '14px', textAlign: 'center', margin: "8px" }} />
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center", backgroundColor: "white", padding: 0, borderRadius: 2, margin: "20px 30px 20px 0px", fontFamily: "poppins" }}>
                            <img src={imageSrc} alt='l' style={{ borderRadius: '10%', width: 100, height: 100, margin: '14px' }} />
                            <Typography variant="h5" gutterBottom style={{ display: 'flex', alignItems: 'flex-start', justifyContent: "start", overflow: 'hidden', flexDirection: "column", padding: 5, margin: 0, }}>
                                <div><p style={{ padding: 0, margin: 0, fontWeight: 700, fontSize: 14, color: "rgb(163 148 148)" }}>Title:</p></div>
                                <div><strong style={{ padding: 0, margin: 0, fontWeight: 400, fontSize: 22, color: "#C07F00", wordBreak: "break-all" }}>{name}</strong></div>
                            </Typography>
                        </div>

                        <div style={{ margin: "20px 30px" }}>
                            <Typography variant="subtitle1" gutterBottom style={{ lineHeight: "0.4", fontSize: 15, overflow: 'hidden', color: "#C07F00" }}>
                                <div><p style={{ padding: 0, marginRight: 80, fontWeight: 700, display: 'flex', alignItems: 'flex-start', justifyContent: "center" }}>For Camera Filters</p></div>
                                <div><p style={{ padding: 0, marginLeft: 80, fontWeight: 700, display: 'flex', justifyContent: "center", }}>To Congratulate/Comment</p></div>
                            </Typography>

                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography style={{ textAlign: 'center', overflow: 'hidden', backgroundColor: "white", fontSize: 14, padding: "5px 60px", border: "solid 0.2px #4C3D3D", color: "#474646", borderRadius: 20 }}>
                                    Install SHARE-O-MATIC app
                                </Typography></div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <div style={{ display: "flex", justifyContent: "center", height: '140px', margin: "10px 0" }}>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "0 30px" }}>
                                    <img src="/stepper.png" alt="Description" style={{ width: '36px', height: '130px', borderRadius: '8px' }} />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: "start" }}>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Button
                                            variant="contained"
                                            href="https://qrco.de/bd6EnR"
                                            className='install-button'
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                fontSize: 14,
                                                minWidth: '120px',
                                                fontWeight: '600',
                                                color: 'black',
                                                backgroundColor: '#FFD95A',
                                                borderRadius: 8,
                                                padding: 10,
                                                overflow: 'hidden'
                                            }}>
                                            Install
                                        </Button>
                                        <p style={{ padding: 0, marginLeft: 20, fontWeight: 700, fontSize: '14px', color: 'rgb(163 148 148)' }}>
                                            (Optional)
                                        </p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: '8px 0' }}>
                                        <p style={{ padding: "0px 20px", margin: 0, fontWeight: 700, fontSize: '16px' }}>
                                            Join relive
                                        </p>
                                        <p style={{ padding: 0, margin: 0, fontWeight: 700, fontSize: '14px', color: 'rgb(163 148 148)' }}>
                                            {/* Optional text */}
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography>Code -</Typography>
                            <Box
                                sx={{
                                    margin: '0 8px',
                                    backgroundColor: 'white',
                                    border: '1px solid grey',
                                    padding: 1,
                                    borderRadius: 1,
                                    fontSize: 14
                                }}
                            >
                                {Code}
                            </Box>
                            <IconButton
                                onClick={handleCopy}
                                sx={{ color: 'rgb(163 148 148)', cursor: 'pointer' }}
                            >
                                <ContentCopyIcon />
                            </IconButton>

                            <Snackbar
                                open={open}
                                autoHideDuration={3000} // Show for 3 seconds
                                onClose={handleClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert severity="success" sx={{ width: '100%' }}>
                                    Code copied to clipboard!
                                </Alert>
                            </Snackbar>
                        </Box>

                        {reliveExist === null &&
                            <Grid item xs={12} style={{ color: 'red', display: 'flex', justifyContent: 'center', overflow: 'hidden', marginBottom: "8px" }}>
                                <CircularProgress />
                            </Grid>
                        }
                        {reliveExpired === true &&
                            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', overflow: 'hidden' }}>
                                <Typography variant="body2" gutterBottom style={{ color: "red", fontSize: "16px", overflow: 'hidden' }}><strong>Relive doesn't exist or is expired</strong></Typography>
                            </Grid>
                        }

                        {reliveNotStarted &&
                            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', overflow: 'hidden' }}>
                                <Typography variant="body2" gutterBottom style={{ color: "red", fontSize: "16px", overflow: 'hidden' }}><strong>Camera shall be available when the event is in progress</strong></Typography>
                            </Grid>
                        }
                    </div>
                </Container>
            ) : os === "android" ? (
                <Container maxWidth="xl" style={{ padding: 0, boxSizing: 'border-box', overflow: 'hidden', backgroundColor: "#FFF7D4", maxHeight: "100vh" }}>
                    <div maxWidth="lg" style={{
                        padding: 0, boxSizing: 'border-box', overflow: 'hidden',
                        minHeight: "100vh",
                        padding: "0px 0px"
                    }}>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "left", backgroundColor: "white", padding: "10px 40px", borderRadius: 10, fontFamily: "poppins" }}>
                            <img src={imageSrc} alt='l' style={{ borderRadius: '10%', width: 100, height: 100, margin: '10px' }} />
                            <Typography variant="h5" gutterBottom style={{ display: 'flex', alignItems: 'flex-start', justifyContent: "start", overflow: 'hidden', flexDirection: "column", padding: 5, margin: 0, }}>
                                <div><p style={{ padding: 0, margin: 0, fontWeight: 700, fontSize: 14, color: "rgb(163 148 148)" }}>Title:</p></div>
                                <div><strong style={{ padding: 0, margin: 0, fontWeight: 400, fontSize: 22, color: "#C07F00", wordBreak: "break-all" }}>{name}</strong></div>
                            </Typography>
                        </div>

                        <div style={{ margin: " 10px", fontSize: 14 }}>
                            <Typography variant="subtitle1" gutterBottom style={{ lineHeight: "0.4", fontSize: 16, overflow: 'hidden' }}>

                                <div style={{ padding: 5 }}><p style={{ padding: 0, fontWeight: 900, display: 'flex', color: "#C07F00", alignItems: 'flex-start', justifyContent: "center" }}>For interactive</p>
                                    <p style={{ padding: 0, display: 'flex', alignItems: 'flex-start', justifyContent: "center", fontWeight: 500 }}>experience</p></div>


                                <div style={{ padding: 5 }}>
                                    <p style={{
                                        color: "#C07F00",
                                        padding: 0, fontWeight: 900, display: 'flex', justifyContent: "center",
                                    }}>For more</p>
                                    <p style={{ padding: 0, display: 'flex', justifyContent: "center", fontWeight: 500 }}>camera options</p></div>


                                <div style={{ padding: 5 }}><p style={{ padding: 0, fontWeight: 900, display: 'flex', justifyContent: "center", color: "#C07F00", }}>To sign</p>
                                    <p style={{ padding: 0, display: 'flex', justifyContent: "center", fontWeight: 500 }}>your pics</p></div>
                            </Typography>

                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Typography style={{ textAlign: 'center', overflow: 'hidden', backgroundColor: "white", fontSize: 14, padding: "4px 60px", wordSpacing: 2, border: "solid 0.8px #4C3D3D", color: "#474646", borderRadius: 20 }}>
                                    Install SHARE-O-MATIC app
                                </Typography></div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ display: "flex", justifyContent: "center", height: '140px', margin: "10px 0" }}>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "0 30px" }}>
                                    <img src="/stepper.png" alt="Description" style={{ width: '36px', height: '130px', borderRadius: '8px' }} />
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>

                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Button
                                            variant="contained"
                                            href="https://qrco.de/bd6EnR"
                                            className='install-button'
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                                minWidth: '120px',
                                                fontSize: 14,
                                                fontWeight: '600',
                                                color: 'black',
                                                backgroundColor: '#FFD95A',
                                                borderRadius: 8,
                                                padding: 10,
                                                overflow: 'hidden'
                                            }}>
                                            Install
                                        </Button>
                                        <p style={{ padding: 0, marginLeft: 20, fontWeight: 700, fontSize: '14px', color: 'rgb(163 148 148)' }}>
                                            (Optional)
                                        </p>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '14px' }}>
                                        <p style={{ padding: "10px 24px", margin: 0, fontWeight: 700, fontSize: '16px' }}>
                                            Join Relive
                                        </p>
                                        <p style={{ padding: 0, margin: 0, fontWeight: 700, fontSize: '14px', color: 'rgb(163 148 148)' }}>
                                            {/* Optional text */}
                                        </p>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography>Code -</Typography>
                            <Box
                                sx={{
                                    margin: '0 8px',
                                    backgroundColor: 'white',
                                    border: '1px solid grey',
                                    padding: 1,
                                    borderRadius: 1,
                                    fontSize: 14
                                }}
                            >
                                {Code}
                            </Box>
                            <IconButton
                                onClick={handleCopy}
                                sx={{ color: 'rgb(163 148 148)', cursor: 'pointer' }}
                            >
                                <ContentCopyIcon />
                            </IconButton>

                            <Snackbar
                                open={open}
                                autoHideDuration={3000} // Show for 3 seconds
                                onClose={handleClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert severity="success" sx={{ width: '100%' }}>
                                    Code copied to clipboard!
                                </Alert>
                            </Snackbar>
                        </Box>


                        <div style={{ color: 'grey', fontWeight: 600, display: 'flex', justifyContent: 'center', overflow: 'hidden', fontSize: '12px', marginTop: '8px' }}>
                            <p style={{ margin: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>or</p>
                        </div>

                        <Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px 0', overflow: 'hidden', borderRadius: "10px" }}>

                            <input
                                type="file"
                                name="fileToUpload"
                                id="fileToUpload"
                                accept="image/*"
                                capture="camera"
                                onChange={fileSelected}
                                style={{ display: 'none', overflow: 'hidden' }} // Hide the input field
                            />
                            <label htmlFor="fileToUpload" className='snap-button' style={{
                                color: 'white',
                                backgroundColor: '#4C3D3D', marginTop: "4px", overflow: 'hidden'
                            }}>
                                <Button component="span" size="small" className='snap-button' style={{
                                    color: 'white',
                                    backgroundColor: '#4C3D3D', minWidth: '300px', fontWeight: "700", fontSize: "14px", overflow: 'hidden'
                                }}>Click pics </Button>
                            </label>
                        </Box>


                        {reliveExist === null &&
                            <Grid item xs={12} style={{ color: 'red', display: 'flex', justifyContent: 'center', overflow: 'hidden', marginBottom: "8px" }}>
                                <CircularProgress />
                            </Grid>
                        }
                        {reliveExpired === true &&
                            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', overflow: 'hidden' }}>
                                <Typography variant="body2" gutterBottom style={{ color: "red", fontSize: "16px", overflow: 'hidden' }}><strong>Relive doesn't exist or is expired</strong></Typography>
                            </Grid>
                        }

                        {reliveNotStarted &&
                            <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '10px', overflow: 'hidden' }}>
                                <Typography variant="body2" gutterBottom style={{ color: "red", fontSize: "16px", overflow: 'hidden' }}><strong>Camera shall be available when the event is in progress</strong></Typography>
                            </Grid>
                        }
                    </div>
                </Container>
            ) : (
                <p>Unknown OS</p>
            )}
        </>
    );
}
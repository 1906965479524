import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/system';
import ResponsiveAppBar from './ResponsiveAppBar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CollageWrapper from './CollageWrapper';
import { useParams } from 'react-router-dom';
import { v4 } from 'uuid'
import { create } from '@mui/material/styles/createTransitions';
import {CollageAPI} from './apis/collage-api';
import {AuthAPI} from './apis/auth-api';

let theme = createTheme({
    palette: {
        primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    components: {

        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#081627',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    marginLeft: theme.spacing(1),
                },
                indicator: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    backgroundColor: theme.palette.common.white,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    margin: '0 16px',
                    minWidth: 0,
                    padding: 0,
                    [theme.breakpoints.up('md')]: {
                        padding: 0,
                        minWidth: 0,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(1),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#4fc3f7',
                    },
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                    fontWeight: theme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: theme.spacing(2),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                },
            },
        },
        Drawer: {
            styleOverrides: {
                root: {
                    fontSize: 114,
                    zIndex: 100
                },
            },
        },
    },
};

const drawerWidth = 256;
export default function EditCollage(props) {
    const [collageTemplate, setCollageTemplate] = React.useState(null);
    const params = useParams();
    const navigate = useNavigate();

    const onPhotoSelected = (id, photo) => {
        setImageSrc(id, photo, collageTemplate)
        localStorage.setItem(params.id, JSON.stringify(collageTemplate))
        setCollageTemplate({ ...collageTemplate })
    }
    const swapPhotos = (srcId, targetId) => {
        const srcImage = getImageObj(srcId, collageTemplate);
        const targetImage = getImageObj(targetId, collageTemplate);
        const srcUrl = srcImage.src;
        srcImage.src = targetImage.src;
        targetImage.src = srcUrl;
        localStorage.setItem(params.id, JSON.stringify(collageTemplate))
        setCollageTemplate({ ...collageTemplate })
    }

    const setImageSrc = (id, src, collageObj) => {
        const img = getImageObj(id, collageObj)
        img.src = src;
    }
    const getImageObj = (id, obj) => {
        if (obj.images) {
            const img = obj.images.find(item => item.id == id);
            if (img) {
                return img;
            }
        }
        if (obj.collages) {
            for (let i = 0; i < obj.collages.length; i++) {
                const img = getImageObj(id, obj.collages[i])
                if (img)
                    return img;

            }
        } else {
            return null;
        }
    }
    
    React.useEffect(() => {
        async function fetchData() {
            try {
                console.log('getting')
                setCollageTemplate(await CollageAPI.getCollage(params.id))
            } catch (ex) {
                console.log('error fetching relives', ex)
            }
        }
        fetchData();
    }, [params.id]);

    const createCollage = async (collage) => {
        try {
            CollageAPI.createCollage(collage)
        } catch (ex) {
            console.log('error creating collage', ex)
        }
    }
    const updateCollage = async (collage) => {
        try {
            CollageAPI.updateCollage(collage)
        } catch (ex) {
            console.log('error updating collage', ex)
        }
    }
    const deleteCollage = async (collage) => {
        try {
           CollageAPI.deleteCollage(collage)
        } catch (ex) {
            console.log('error deleting collage', ex)
        }
    }
    const getImageObjs = (obj) => {
        let images = [];
        if (obj.collages) {
            for (let i = 0; i < obj.collages.length; i++) {
                images.push(getImageObjs(obj.collages[i]))
            }
        }
        if (obj.images) {
            images.push(obj.images);
        }
        return images.flat();
    }
    const onCollageDelete = async () => {
        if (collageTemplate._id) {
            deleteCollage(collageTemplate)
            return navigate('/collages')
        }
    }
    const onCollageSave = async () => {
        console.log('saving')
        CollageAPI.saveCollage(collageTemplate)
    }
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <ResponsiveAppBar user={AuthAPI.getUser()}></ResponsiveAppBar>
                    <Stack
                        sx={{ flex: 1, flexDirection: 'column' }}
                    >
                        <AppBar position="static" elevation={0} style={{ background: '#ffffff' }}>
                            <Container maxWidth="xl">
                                <Toolbar disableGutters >
                                    <Box sx={{ flexGrow: 1 }}>
                                    </Box>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Button>Download</Button>
                                        <Button onClick={onCollageSave}>Save</Button>
                                        <Button onClick={onCollageDelete}>Delete</Button>
                                    </Box>
                                </Toolbar>
                            </Container>
                        </AppBar>
                        <Box sx={{ height: '100%', background: '#ECECEC' }}>
                            <>
                                {collageTemplate &&
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                                        <div style={{ margin: '5px', display: 'flex', border: `${collageTemplate.border}px solid gray`, width: `${collageTemplate.width}px`, height: `${collageTemplate.height}px` }}>
                                            <CollageWrapper onSwapPhotos={swapPhotos} onPhotoSelected={onPhotoSelected} template={collageTemplate} border={collageTemplate.border} ></CollageWrapper>
                                        </div>
                                    </div>
                                }</>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </ThemeProvider>

    );
}
let userInfo = null;
// const userInfo = {
//   "authenticated": true,
//   "email": "coderover@gmail.com",
//   "first_name": "Safi",
//   "id": "9zXNsqyZhzWQSSdYs814rpyBg3u2", //878776 - prod
//   "last_name": "",
//   "name": "",
//   "profilePic": "https://vwish-profile-pics-prod.s3.amazonaws.com/878776",
//   "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDE4MDQ3MDcsImV4cCI6MTczMzM0MDcwNywiYXVkIjoiOXpYTnNxeVpoeldRU1NkWXM4MTRycHlCZzN1MiIsInN1YiI6Ijl6WE5zcXlaaHpXUVNTZFlzODE0cnB5QmczdTIiLCJHaXZlbk5hbWUiOiJKb2hubnkiLCJTdXJuYW1lIjoiUm9ja2V0IiwiRW1haWwiOiJqcm9ja2V0QGV4YW1wbGUuY29tIiwiUm9sZSI6WyJNYW5hZ2VyIiwiUHJvamVjdCBBZG1pbmlzdHJhdG9yIl19.GCowDnYnjnbrJ4U0TZFMxj8VOUOXxBjmx4ZGOFgm9lE"
//   //prod token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjg3ODc3NiwiZXhwIjozNzY5NDI1NDgwOCwiaWF0IjoxNjk0MjU4NDA4fQ.onqfV2JFs1pE-GHY9irV0AVFIebIX79htQntx7rMZnY
// };

export const AuthAPI = {
    login: async (data) => {
        data.id = data.user_id;
        userInfo = data;
        userInfo.id = data.user_id;
    },
    setUser: (data) => {
        userInfo = data
        localStorage.setItem('user', JSON.stringify(data));
    },
    getUser: () => {
        if (userInfo) {
            return userInfo
        }
        try {
            return JSON.parse(localStorage.getItem('user'))
        } catch (ex) {
            return null;
        }
    }
}

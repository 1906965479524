import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PermMediaOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActual';
import { useNavigate } from 'react-router-dom'
import { RelivesAPI } from './apis/relives-api';
import { AuthAPI } from './apis/auth-api';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;
  const navigate = useNavigate();
  let [relives, setRelives] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      try {
        if (!AuthAPI.getUser()) {
          return navigate('/login')
        }
        const relives = await RelivesAPI.getRelives()
        setRelives(relives)
        props.onRelivSelected(relives[0].eventcode)
      } catch (ex) {
        console.log('error fetching relives', ex)
      }
    }
    fetchData();
  }, []);


  return (
    <Drawer variant="permanent" {...other} z-index={100}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          Relives App
        </ListItem>
        <Box sx={{ bgcolor: '#101F33' }}>
          <ListItem sx={{ py: 2, px: 3 }}>
            <ListItemText sx={{ color: '#fff' }}>Relives</ListItemText>
          </ListItem>
          {relives && relives.map(({ eventcode, name, active }) => (
            <ListItem disablePadding key={eventcode}>
              <ListItemButton selected={active} sx={item} onClick={(e) => props.onRelivSelected(eventcode)}>
                <ListItemIcon><PermMediaOutlinedIcon /></ListItemIcon>
                <ListItemText>{name}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}

          <ListItem>
            <ListItemButton sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }} onClick={(e) => navigate('/collages')}>
              <ListItemText>Collages</ListItemText>
            </ListItemButton>
          </ListItem>
        </Box>
      </List>
    </Drawer>
  );
}
import './App.css';
import * as React from 'react';
import Login from './Login';
import Home from './Home';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useParams } from 'react-router-dom';

import Collages from './Collages';
import EditCollage from './EditCollage'
import SelectPhotos from './SelectPhotos'
import TestComp from './TestComp'
import Photos from './Photos';

function App() {
  const params = useParams();
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      // event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
     const user = JSON.parse(localStorage.getItem('user') || {} );
     localStorage.clear();
     localStorage.setItem('user', JSON.stringify(user));
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/"
          element={<Home />} />
        {/* <Route path="/test"
          element={<TestComp name="Somebody" />} /> */}
        <Route path="/collages"
          element={<Collages />} />
        <Route path="/collage/:id"
          element={<EditCollage />} />
        <Route path="/select"
          element={<SelectPhotos />} />
        <Route path="/login" element={<Login />} />
        <Route path="/photos/:id" element={<Photos />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

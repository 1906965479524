import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Container } from '@mui/system';
import { Button, Card, Checkbox, Grid, IconButton, ImageListItemBar, Toolbar } from '@mui/material';
import ModalImage from "./ModalImage";
import { RelivesAPI } from './apis/relives-api';

export default function RelivImages(props) {
  let [selectAll, setSelectAll] = React.useState(false);
  let [selections, setSelections] = React.useState(Array((props.thumbs || []).length).fill(false));

  const onPhotoSelected = (e, index) => {
    if (e.target.checked) {
      selections[index] = true;
    } else {
      selections[index] = false;
    }
    setSelections([...selections]);
  }

  const onSelectAll = async () => {
    setSelections(selections.fill(!selectAll))
    setSelectAll(!selectAll)
  }

  const downloadSelected = async () => {
    const images = selections.map((selected, index) => {
      if (selected) {
        const img = props.images[index];
        return img.substring(img.lastIndexOf('/') + 1, img.indexOf('?'))
      } else {
        return null;
      }
    }).filter(val => val != null);
    if (images.length === 0) {
      return;
    }
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { "Authorization": `Bearer ${props.user.token || ''}` },
    //   body: JSON.stringify({ reliveId: props.relivId, photos: images }),
    //   withCredentials: true
    // };
    // const response = await fetch(`https://api.dev.webshareomatic.com/memory/download`, requestOptions);
    // if (!response.ok && response.status === 401) {
    //   // navigate('/login')
    //   console.log('something went wrong')
    // }
    const data = await RelivesAPI.downloadPhotos(props.relivId, images )
    let a = document.createElement('a');
    a.href = data.url
    a.target = '_parent';
    (document.body || document.documentElement).appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  }

  React.useEffect(() => {
    setSelections(Array((props.thumbs || []).length).fill(false))
    setSelectAll(false);
  }, [props.thumbs]);
  React.useEffect(() => {
  }, [selections]);

  // let [photos, setphotos] = React.useState(null);
  // const navigate = useNavigate();
  // React.useEffect( () => {
  //   async function fetchData() {
  //     try {
  //       if (!props.relivId) {

  //         throw new Error(`RelivId is missing`);
  //       }
  //       if (!props.user || !props.user.token) {
  //         navigate('/login')
  //       }
  //       let headers = { "Authorization": `Bearer ${props.user.token || ''}` };
  //       const response = await fetch(`mediaserver/event/${props.relivId}/list`, { headers, withCredentials: true });
  //       if (!response.ok && response.status === 401) {
  //         navigate('/login')
  //       }
  //       const data = await response.json()
  //       if (Array.isArray(data.message)) {
  //         setphotos(data.message)
  //       }
  //     } catch (ex) {
  //       console.log('error', ex)
  //     }
  //   }
  //   fetchData();
  // }, [props.relivId]);

  return (
    <Container>
      {!props.images || props.images.length === 0 ?
        <div> No photos available</div> :
        <div>
          <Toolbar>
            <Grid container spacing={2} alignItems="left">
              <Grid item>
                <Button variant="contained" onClick={onSelectAll}>
                  {!selectAll ? "Select All" : "Unselect All"}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={downloadSelected}>
                  Download Selected Photos
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
          <ImageList
            gap={12}
            sx={{
              mb: 8,
              gridTemplateColumns: 'repeat(auto-fill, minmax(150px,1fr)) !important'
            }}
          >
            {props.thumbs && props.thumbs.map((item, index) => (
              <ImageListItem
                sx={{ width: '150px !important' }}
                key={item}>
                <ModalImage
                  small={props.thumbs[index]}
                  large={props.images[index]}
                  alt=""
                />;
                <ImageListItemBar
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  }}
                  position="top"
                  actionIcon={
                    <Checkbox sx={{ color: 'white' }} onChange={(e) => onPhotoSelected(e, index)} checked={selections[index] || false} />
                  }
                  actionPosition="left"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      }
    </Container>
  );
}


import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import QRCode from "react-qr-code";
import { Alert, CircularProgress, stepperClasses } from '@mui/material';
import {AuthAPI} from './apis/auth-api';
import { wsURL } from './config';

const theme = createTheme();

export default function Login(props) {
    const [error, setError] = React.useState(null);
    const [qrcode, setQrcode] = React.useState(null);
    const [ws, setWs] = React.useState(new WebSocket(wsURL));
    
    const navigate = useNavigate();

    React.useEffect(() => {
        ws.onopen = (e) => {
            console.log('connected to ws')
            const message = { action: "sendmessage", message: "echo message" };
            ws.send(JSON.stringify(message));
        }

        ws.onmessage = (e) => {
            try {
                const message = JSON.parse(e.data);
                console.log(message)
                if (message.qrcode) {
                    console.log(message.qrcode)
                    setQrcode(message.qrcode)
                } else if (message.token) {
                    AuthAPI.setUser(message)
                    navigate("/")
                } else {
                    setError("unable to get qrcode")
                }
            } catch (ex) {
                console.log(ex)
                setError("failed to get qrcode")
            }
        }
        ws.onerror = (e) => {
            console.log('connection error')
            console.log(e)
            setError("unable to connect")
        }
        ws.onclose = () => {
        }
        return () => {
        }
    }, [ws.onmessage, ws.onopen, ws.onclose]);

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Scan
                    </Typography>
                    <Box noValidate sx={{ mt: 1, padding: 5 }}>
                        {!error && qrcode && <QRCode value={qrcode} size={256} />}
                        {!error && !qrcode && <CircularProgress />}
                        {error &&
                            <Alert severity="error">Failed to get QR code, please Refresh page</Alert>
                        }
                    </Box>
                </Box>
            </Container>
        </ThemeProvider >
    );
}
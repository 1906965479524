import { AuthAPI } from './auth-api';
import { apiKey, baseUrl } from '../config';

let collages = null;
export const CollageAPI = {
    getCollages: async () => {
        if (!collages && !localStorage.getItem('collages')) {
            let headers = { "Authorization": `Bearer ${AuthAPI.getUser().token}`, 'x-api-key': `${apiKey}`  };
            const response = await fetch(`${baseUrl}/collages/collage/byuser`, { headers, withCredentials: true });
            if (!response.ok && response.status === 401) {
                //what to do?
            }
            const data = await response.json()
            collages = data.collages;
            localStorage.setItem('collages', JSON.stringify(collages))
            return data.collages
        } else {
            console.log('using cache')
            collages = JSON.parse(localStorage.getItem('collages') || [])
            return collages;
        }
    },
    getCollage: async (id) => {
        if (!collages || collages.length === 0) {
            collages = JSON.parse(localStorage.getItem('collages') || [])
        }
        const collage = collages.find((item) => item.id == id)
        return collage
    },
    //creates a local copy 
    createCollage: async (collage) => {
        if (!collages || collages.length === 0) {
            collages = JSON.parse(localStorage.getItem('collages') || [])
        }
        collages.push(collage)
        localStorage.setItem('collages', JSON.stringify(collages))
    },
    saveCollage: async (collage) => {
        const requestOptions = {
            headers: { "Authorization": `Bearer ${AuthAPI.getUser().token || ''}`, 'x-api-key': `${apiKey}`  },
            body: JSON.stringify(collage),
            withCredentials: true
        };
        let response = null;
        if (collage._id) {
            requestOptions.method = 'PUT';
            response = await fetch(`${baseUrl}/collages/collage/${collage.id}`, requestOptions);
        } else {
            requestOptions.method = 'POST';
            response = await fetch(`${baseUrl}/collages/collage`, requestOptions);
        }
        if (!response.ok && response.status === 401) {
            console.log('what to do !!!')
        }
        localStorage.setItem('collages', JSON.stringify(collages))
    },

    deleteCollage: async (collage) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { "Authorization": `Bearer ${AuthAPI.getUser().token || ''}`, 'x-api-key': `${apiKey}`  },
            withCredentials: true
        };
        const response = await fetch(`${baseUrl}/collages/collage/${collage.id}`, requestOptions);
        if (!response.ok && response.status === 401) {
            console.log('what to do !!!')
        }
    }
}
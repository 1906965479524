import * as React from 'react';
import { Avatar, Box, IconButton, Stack } from '@mui/material';
import template1 from './template1.jpg';
import template2 from './template2.jpg';
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid';
import {CollageAPI} from './apis/collage-api';

const templates = [
{
    border: 5,
    width: 500,
    height: 600,
    type: 'collage',
    orientation: 'row',
    size: 1,
    thumb:template1,
    collages: [
        {
            type: 'collage',
            orientation: 'column',
            size: .5,
            images: [
                {
                    id: 4,
                    type: 'image',
                    size: .5,
                },
                {
                    id: 5,
                    type: 'image',
                    size: .5,
                }
            ]
        },
        {
            type: 'collage',
            orientation: 'column',
            size: .5,
            images: [
                {
                    id: 6,
                    type: 'image',
                    size: .5,
                },
                {
                    id: 7,
                    type: 'image',
                    size: .5,
                }
            ]
        }
    ]
},
{
    border: 5,
    width: 500,
    height: 600,
    type: 'collage',
    orientation: 'row',
    size: 1,
    thumb:template2,
    collages: [
        {
            type: 'collage',
            orientation: 'column',
            size: .5,

            images: [
                {
                    id: 2,
                    type: 'image',
                    size: .5,
                },
                {
                    id: 3,
                    type: 'image',
                    size: .5,
                }
            ],
        },
        {
            type: 'collage',
            orientation: 'column',
            size: .5,
            images: [
                {
                    id: 4,
                    type: 'image',
                    size: .5,
                },
                {
                    id: 5,
                    type: 'image',
                    size: .5,
                }
            ]
        },
        {
            type: 'collage',
            orientation: 'column',
            size: .5,
            images: [
                {
                    id: 6,
                    type: 'image',
                    size: .5,
                },
                {
                    id: 7,
                    type: 'image',
                    size: .5,
                }
            ]
        }
    ]
}
]

export default function SelectTemplate(props) {
    const navigate = useNavigate();
    const onTemplateSelected = (index) => {
        const id = uuid();
        const newCollageId = id
        const collage = {...templates[index]};
        collage.id = newCollageId;
        CollageAPI.createCollage(collage);
        navigate(`/collage/${newCollageId}`)
    }
    return (
        <>
            <Stack direction="row"
                spacing={2}
                padding={"20px"}
                sx={{
                    overflow: "hidden",
                    overflowX: "scroll",
                }}
            >
                <IconButton onClick={() => onTemplateSelected(0)} >
                    <img src={template1} />
                </IconButton>
                <IconButton onClick={() => onTemplateSelected(1)}  >
                    <img src={template2} />
                </IconButton>
            </Stack>
        </>
    );
}

